.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  align-items: stretch;
  min-height: 2rem;
}

.wrapper:first-of-type div {
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.borderWrapper {
  padding: 0.5rem 0;
  background-color: var(--COLOR_ON_SURFACE);
  border: 1px solid var(--COLOR_SURFACE);
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.24);
  border-radius: 0.125rem;
}

.tabContainer {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(3rem, 10rem)); */
  align-items: stretch;
  white-space: nowrap;
  overflow: hidden;

  padding: 0;
  border: 0;
}

.wrapper button * {
  cursor: pointer;
}

.rightTools {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.rightTools button {
  margin-right: 0.5rem;
}

.leftTools {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.add {
  margin-right: 2.5rem;
}
