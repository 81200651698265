.wrapper {
  width: 100%;
  background-color: var(--COLOR_SURFACE);
  color: var(--COLOR_BACKGROUND);
  padding: 0.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.direction {
  display: flex;
  border: 1px solid var(--COLOR_OUTLINE);
  border-radius: 3px;
}

.direction .sell {
  background-color: var(--COLOR_ON_SURFACE);
  color: var(--COLOR_OUTLINE);
}
.direction .buy {
  background-color: var(--COLOR_ON_SURFACE);
  color: var(--COLOR_OUTLINE);
}

.direction .buy.active {
  background-color: var(--COLOR-GREEN-ONE);
  color: var(--COLOR_ON_SURFACE);
}
.direction .buy.active:hover {
  background-color: var(--COLOR-GREEN-ONE);
  color: var(--COLOR_ON_SURFACE);
}
.direction .sell.active {
  background-color: var(--COLOR_ERROR);
}
.direction .sell.active:hover {
  background-color: var(--COLOR_ERROR);
}

.direction .sell:hover {
  background-color: transparent;
}

.direction .buy:hover {
  background-color: transparent;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.field {
  display: flex;
  width: 184px;
}

.preset {
  background-color: transparent;
  color: var(--COLOR_BACKGROUND);
  border: none;
  cursor: pointer;
  padding: 1.5px 3px;
  width: 2rem;
  border-radius: 0.25rem;
  text-align: center;
}

.preset:hover {
  background-color: var(--COLOR_SECONDARY);
  color: var(--COLOR_ON_SURFACE);
}

.active {
  background-color: var(--COLOR_SECONDARY);
  color: var(--COLOR_ON_SURFACE);
}

.line {
  margin-bottom: 0.25rem;
}

.line::before {
  content: '';
  width: 6.5rem;
  height: 1.75rem;
  position: absolute;
  left: 0.5rem;
  border-bottom: 1px solid var(--COLOR_SURFACE);
  pointer-events: none;
}

.today {
  width: 100%;
}
