.wrapper {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
}

.wrapperSvg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.iconContainerXXS {
  width: var(--SVG-SIZE-XXS);
  height: var(--SVG-SIZE-XXS);
}

.iconContainerXS {
  width: var(--SVG-SIZE-XS);
  height: var(--SVG-SIZE-XS);
}

.iconContainerS {
  width: var(--SVG-SIZE-S);
  height: var(--SVG-SIZE-S);
}

.iconContainerM {
  width: var(--SVG-SIZE-M);
  height: var(--SVG-SIZE-M);
}

.iconContainerL {
  width: var(--SVG-SIZE-L);
  height: var(--SVG-SIZE-L);
}

.iconContainerXL {
  width: var(--SVG-SIZE-XL);
  height: var(--SVG-SIZE-XL);
}

.iconContainerFull {
  width: 100%;
  height: 100%;
}
