.attach {
  padding: 2px;
}

.drag {
  background-color: var(--COLOR_OUTLINE);
}

.nodrag {
  background-color: var(--COLOR_ON_SURFACE);
}

.alertWrapper {
  width: 19rem;
  position: fixed;
  top: 6rem;
  right: 10px;
  z-index: 99;
}
