.wrapper {
  display: flex;
  align-items: center;
}

.leftIcon {
  margin-right: 3px;
}

.rightIcon {
  margin-left: 3px;
}

.leftIcon,
.rightIcon {
  max-height: 1.3rem;
  color: var(--COLOR_SECONDARY);
}

.text {
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
}

.subtitle {
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
}
