.wrapper {
  position: relative;
  width: 100%;
}

.input :global .MuiInputBase-root {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  background-color: var(--COLOR_ON_SURFACE);
  font-size: var(--FONT-SIZE-S);
  color: var(--COLOR_OUTLINE);
  line-height: 1.5rem;
  max-height: 6.5rem;
  min-height: 2.5rem;
  padding: 0 0.25rem;
}

.input :global .Mui-focused {
  outline: 2px solid var(--COLOR_SECONDARY);
}

.input :global .MuiOutlinedInput-input {
  max-width: calc(100% - 2rem);
}

.type {
  color: var(--COLOR_SECONDARY);
  cursor: pointer;
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-S);
}

.close {
  cursor: pointer;
  color: var(--COLOR_OUTLINE);
}
