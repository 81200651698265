.wrapperContact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  overflow-y: auto;
}

.contactChatInfoItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-M);
  line-height: 1.5rem;
  width: 100%;
  position: relative;
  padding: 0 0.5rem 0 1rem;
  cursor: pointer;
}

.icon {
  display: none;
}

.contactChatInfoItem:hover {
  background: rgba(235, 239, 244, 0.08);
  box-shadow: 0.5rem 0 0 0 rgba(235, 239, 244, 0.08),
    -0.5rem 0 0 0 rgba(235, 239, 244, 0.08);
}

.contactChatInfoItem:hover .icon {
  display: flex;
  gap: 15px;
}

.contactChatInfoItem p {
  color: var(--COLOR_BACKGROUND);
  flex: 1 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contactChatInfoItem span {
  color: var(--COLOR_OUTLINE);
}

.contactItemOrg {
  display: flex;
  min-width: 0;
  max-width: 40%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.organizationName {
  text-overflow: ellipsis;
  overflow-x: hidden;
}
