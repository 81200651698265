.wrapperContact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0.5rem;
  overflow-y: overlay;
  margin-top: 0.5rem;
  height: calc(80vh - (2rem + 120px + 48px + 52px));
}

.contactItem,
.contactChatInfoItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-S);
  line-height: 1rem;
  width: 100%;
  position: relative;
  cursor: pointer;
  padding: 0.5rem 0;
  gap: 0.5rem;
}

.icon {
  position: absolute;
  right: 0;
  display: flex;
  gap: 15px;
}

.contactItem .icon,
.contactChatInfoItem .icon {
  visibility: hidden;
  flex-shrink: 0;
}

.contactItem:hover {
  background: var(--COLOR_BLACK_80);
  box-shadow: 0.5rem 0 0 0 var(--COLOR_BLACK_80),
    -0.5rem 0 0 0 var(--COLOR_BLACK_80);
}

.contactChatInfoItem:hover {
  background: rgba(235, 239, 244, 0.08);
  box-shadow: 0.5rem 0 0 0 rgba(235, 239, 244, 0.08),
    -0.5rem 0 0 0 rgba(235, 239, 244, 0.08);
}

.contactItem:hover .icon,
.contactChatInfoItem:hover .icon {
  visibility: visible;
}

.contactItem p,
.contactChatInfoItem p {
  color: var(--COLOR_BACKGROUND);
}

.contactItem span,
.contactChatInfoItem span {
  color: var(--COLOR_OUTLINE);
}

.isActive {
  background-color: var(--COLOR_ON_SECONDARY);
  box-shadow: 0.5rem 0 0 0 var(--COLOR_ON_SECONDARY),
    -0.5rem 0 0 0 var(--COLOR_ON_SECONDARY);
}

.isActive:hover {
  background-color: var(--COLOR_ON_SECONDARY);
  box-shadow: 0.5rem 0 0 0 var(--COLOR_ON_SECONDARY),
    -0.5rem 0 0 0 var(--COLOR_ON_SECONDARY);
}

.contactItemOrg {
  display: flex;
  flex: 1;
  min-width: 0;
}

.contactItemOrg span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% * 0.9);
}

@media screen and (max-width: 830px) {
  .wrapperContact {
    height: calc(80vh - (2rem + 218px));
  }
}
