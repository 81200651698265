.form {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
}

.form label {
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-XS);
  line-height: 1rem;
  color: var(--COLOR_BACKGROUND);
  padding-top: 0.5rem;
}
