.root {
  background-color: var(--COLOR_BLACK_80);
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
}

.body {
  background-color: var(--COLOR_SURFACE);
  display: flex;
  flex-direction: row;
  flex: 1;
}
