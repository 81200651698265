.wrapper {
  color: var(--COLOR_BACKGROUND);
}

.wrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 20rem;
  overflow: auto;
}

.label {
  flex: 1;
}

.highlights {
  color: var(--COLOR_SECONDARY);
}

.item {
  color: var(--COLOR_BACKGROUND);
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
}

.item:hover {
  background-color: #67571a;
}

.input :global .MuiInputBase-root {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  background-color: var(--COLOR_ON_SURFACE);
  font-size: var(--FONT-SIZE-S);
  color: var(--COLOR_OUTLINE);
  line-height: 1.5rem;
  max-height: 6.5rem;
  min-height: 2.5rem;
  padding: 0 0.25rem;
  color: var(--COLOR_BACKGROUND);
  border: 1px solid var(--COLOR_OUTLINE);
}

.input :global .Mui-focused {
  border: 2px solid var(--COLOR_SECONDARY);

  outline: none;
}
.input :global .MuiInputBase-root:hover {
  border: 2px solid var(--COLOR_SECONDARY);
  outline: none;
}
.input :global .MuiInputBase-root:focus {
  border: 2px solid var(--COLOR_SECONDARY);
  outline: none;
}
.input :global .MuiInputBase-root:active {
  border: 2px solid var(--COLOR_SECONDARY);
  outline: none;
}

.input :global .MuiOutlinedInput-input {
  width: 100%;
}

.wrapper :global .MuiSvgIcon-root {
  fill: var(--COLOR_BACKGROUND);
}

.readOnly :global .MuiInputBase-root:hover {
  border: 1px solid #6d7385;
  color: #6d7385;
  outline: none;
}
.readOnly :global .MuiInputBase-root {
  border: 1px solid #6d7385;
  outline: none;
  color: #6d7385;
  fill: #6d7385;
}
.readOnly :global .MuiSvgIcon-root {
  fill: #6d7385;
}

.wrapper :global .MuiAutocomplete-clearIndicator {
  display: none;
}

.additional {
  margin-left: auto;
  font-size: var(--FONT-SIZE-XS);
  color: #adb0bc;
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
