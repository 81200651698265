.wrapper {
  position: absolute;
  right: 0;
  background-color: var(--COLOR_ON_SURFACE);
  color: var(--COLOR_BACKGROUND);
  min-width: 600px;
  z-index: 6000;
}

.list {
  max-height: 30rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-top: 1px solid var(--COLOR_SURFACE);
}

.start {
}

.end {
  display: flex;
}
.end button:first-of-type {
  margin-right: 1rem;
}
