.wrapper {
  position: relative;
  z-index: 100;
}

.input {
  appearance: none;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 2px;
  margin: 0.37rem;
  border: 2px solid var(--COLOR_OUTLINE);
  outline: none;
  cursor: pointer;
  background-color: var(--COLOR_SURFACE);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.input:disabled {
  border-color: var(--COLOR-GRAY-TWO);
  background-color: var(--COLOR-GRAY-TWO);
}

.input:disabled + .label {
  color: var(--COLOR_SURFACE);
}

.input:not(:disabled):checked {
  background-color: var(--COLOR_SECONDARY);
}

.input:checked::after {
  font-weight: var(--FONT-WEIGHT-L);
  color: var(--COLOR_SURFACE);
  font-size: 1.5em;
  position: absolute;
}

.check:checked::after {
  content: url('../../../assets//icons//check.png');
  bottom: -5px;
}

.minus:checked::after {
  content: url('../../../assets//icons//minus.png');
  bottom: -1px;
}

.input:not(:disabled):hover::before {
  content: '';
  width: 1rem;
  height: 1rem;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 0.75rem var(--COLOR-GRAY-ONE);
  z-index: -10;
}
