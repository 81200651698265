.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--COLOR_ON_SURFACE);
  position: relative;
  max-width: calc(100% - 700px);
  min-width: calc(100% - 700px);
  max-height: 100vh;
}

.blotter {
  overflow-x: auto;
  height: 100%;
}

.rowTicketReceive {
  background-color: var(--COLOR_ON_SECONDARY);
  color: var(--COLOR_BACKGROUND);
}

.rowTicketDecline {
  background-color: transparent;
  color: #8d91a0;
}

.rowTicketSent {
  background-color: var(--COLOR_PRIMARY_CONTAINER);
  color: var(--COLOR_BACKGROUND);
}

.rowTicketDefault {
  background-color: transparent;
  color: var(--COLOR_BACKGROUND);
}

/* .blotter table tbody tr:nth-child(odd) {
  background-color: var(--COLOR_SURFACE);
} */

.blotter table tbody tr {
  cursor: pointer;
}

.blotter::-webkit-scrollbar-corner {
  background: var(--COLOR_ON_SURFACE);
}

.blotter table tbody tr td div {
  height: 1.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.blotter table tbody tr:hover {
  background-color: rgba(242, 205, 64, 0.2);
}

.blotter thead th {
  color: var(--COLOR-GRAY-BLOTTER);
  border: 1px solid var(--COLOR-GRAY-BLOTTER);
  height: 2rem;
  position: sticky;
  top: 0;
  background-color: var(--COLOR_ON_SURFACE);
}

.blotter thead th:hover {
  border: 1px solid var(--COLOR_SECONDARY);
}

.blotter table,
th,
td {
  border: none;
}

.wideColumn210 {
  width: 210px;
  max-width: 210px;
  min-width: 210px;
}

.wideColumn130 {
  width: 130px;
  max-width: 130px;
  min-width: 130px;
}
