.wrapper {
  width: 26rem;
  background-color: var(--COLOR_ON_SURFACE);
  border: 1px solid var(--COLOR_OUTLINE);
}

.footer {
  display: flex;
  justify-content: space-between;
  background-color: var(--COLOR_SURFACE);
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  column-gap: 0.75rem;
}

.footer button {
  width: 48%;
}

.itemWrapper {
  display: flex;
  column-gap: 0.5rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.itemWrapper div {
  flex-basis: 50%;
}

.field {
  color: var(--COLOR_OUTLINE);
}

.value {
  color: var(--COLOR_BACKGROUND);
}

.content {
  padding: 0 0.5rem;
}

.contentSection {
  padding-bottom: 0.5rem;
  position: relative;
}

.contentSection:not(:last-of-type):after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 1rem);
  background-color: var(--COLOR_SURFACE);
  height: 1px;
  padding: 0 0.5rem;
}

.leg {
  background-color: var(--COLOR_SURFACE);
  border: 1px solid var(--COLOR-GRAY-TWO);
  border-radius: 2px;
  padding: 0.5rem 0.5rem 0.5rem;
  margin-top: 0.5rem;
}

.leg [class*='itemWrapper'] {
  padding-top: 0.25rem;
}

.legSubtitle {
  color: var(--COLOR-GRAY-FIVETEEN);
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-XS);
}
