.wrapper {
  cursor: pointer;
  /* min-width: 10.625rem; */
}

.listItem {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--COLOR-GRAY-TEN);
  color: var(--COLOR_BACKGROUND);
}

.listItem:hover {
  background-color: var(--COLOR-GRAY-FOURTEEN);
}

.labelOnList {
  max-width: calc(80%);
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.textStyle {
  margin-top: 5px;
}
