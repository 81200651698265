.wrapper {
  display: flex;
  padding: 0.625rem 0;
  flex-direction: column;
}
.wrapper ul {
  margin-bottom: 0;
  padding: 0.625rem 0;
}
.wrapper li {
  width: 12.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
}

.settings {
  max-height: 30rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.checkSection {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.iconSection {
  display: flex;
  align-items: center;
}

.drag,
.sort {
  opacity: 0;
}

.wrapper li:hover .sort,
.wrapper li:hover .drag,
.sorted {
  opacity: 1;
}

.sortDown {
  transform: rotate(180deg);
}
