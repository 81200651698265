.itemWrapper {
  display: flex;
  column-gap: 0.5rem;
  padding: 0.5rem 0 0 0;
}

.itemWrapper div {
  flex-basis: 50%;
}

.field {
  color: var(--COLOR_OUTLINE);
}

.value {
  color: var(--COLOR_BACKGROUND);
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
}

.value [class*='wrapperSvg'] {
  top: 10px;
}

.accepted {
  color: var(--COLOR-GREEN-ONE);
}
