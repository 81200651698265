.wrapper {
  display: inline-flex;
  align-items: center;
  font-size: var(--FONT-SIZE-S);
  border-radius: 2px;
  padding: 0 4px;
  color: var(--COLOR_BACKGROUND);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 0.3rem;
}

.text {
  padding: 0 0.375rem;
  max-width: 11rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper:not(.enabled):hover {
  background-color: #414140;
}
.wrapper:not(.enabled):active {
  background-color: #957e27;
}
.enabled {
  background-color: var(--COLOR_SECONDARY);
  color: var(--COLOR_ON_SURFACE);
}
.enabled svg {
  fill: var(--COLOR_ON_SURFACE);
}
