.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background: var(--COLOR_ON_SURFACE);
  flex: 1;
}

.title {
  margin: 0;
  font-weight: var(--FONT-WEIGHT-M);
  font-size: var(--FONT-SIZE-M);
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--COLOR_BACKGROUND);
}
