.container {
  box-sizing: border-box;
  border: 1px solid var(--COLOR_OUTLINE);
  border-radius: 2px;
  background: var(--COLOR_BLACK_80);
  width: 30rem;
}

.content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex: 1;
  justify-content: space-between;
  gap: 1px;
  flex-direction: column;
  max-width: 100%;
}

.contentItem {
  display: flex;
  flex-direction: column;
  background: var(--COLOR_ON_SURFACE);
  align-self: stretch;
  flex: 0 0 calc(100% / 3 - 1px);
  height: 48rem;
}

.wrapperButtons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 0.25rem;
  padding: 0.5rem;
}

.wrapperButtons button {
  border: none;
  background-color: transparent;
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-S);
  line-height: 1.25rem;
  color: var(--COLOR_SECONDARY);
  padding: 0;
  cursor: pointer;
}

.wrapperButtons button:disabled {
  color: var(--COLOR-GRAY-TWO);
  cursor: default;
}

@media screen and (max-width: 500px) {
  .container {
    width: 20rem;
  }

  .content {
    flex-direction: column;
    max-width: 100%;
  }
}
