:root {
  --FONT-SIZE-XXS: 10px;
  --FONT-SIZE-XS: 12px;
  --FONT-SIZE-S: 14px;
  --FONT-SIZE-M: 16px;

  --FONT-WEIGHT-S: 400;
  --FONT-WEIGHT-M: 500;
  --FONT-WEIGHT-L: 700;

  --SVG-SIZE-XXS: 10px;
  --SVG-SIZE-XS: 14px;
  --SVG-SIZE-S: 16px;
  --SVG-SIZE-M: 20px;
  --SVG-SIZE-L: 24px;
  --SVG-SIZE-XL: 32px;

  --FONT-FAMILY-MAIN: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.133);
  border-radius: 8px;
  background-clip: content-box;
  border: 3px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  transition: all 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
}

/* Remove outline on the forms and links */
:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

* {
  margin: 0;
  font-family: var(--FONT-FAMILY-MAIN);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-picker-panel-container {
  background-color: #171920 !important;
}

.ant-picker-cell-inner {
  color: #edeef2 !important;
}

.ant-picker-content th {
  color: #edeef2 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #f2cd40 !important;
  color: #171920 !important;
}
.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #f2cd40 !important;
  color: #171920 !important;
}
.ant-picker-header-view {
  color: #edeef2 !important;
}
.ant-picker-clear span svg {
  fill: #edeef2 !important;
  color: #171920 !important;
  background-color: #171920 !important;
}
.ant-picker-suffix span svg {
  fill: #edeef2 !important;
}
.ant-picker {
  background-color: #171920 !important;
}

.ant-picker-input input {
  color: #edeef2 !important;
}
.ant-picker input::placeholder {
  color: #8d92a0 !important;
}
.ant-picker-cell-inner::before {
  border-color: #f2cd40 !important;
}
.ant-picker-cell-in-range .ant-picker-cell-inner {
  background-color: #2e3240 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  background-color: #2e3240 !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #2e3240 !important;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: #2e3240 !important;
}
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #f2cd40 !important;
  color: #171920 !important;
}
.ant-picker-dropdown .ant-picker-cell-disabled .ant-picker-cell-inner {
  color: #6d7385 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #edeef2;
  -webkit-box-shadow: 0 0 0px 1000px #171920 inset;
  transition: background-color 5000s ease-in-out 0s;
}
