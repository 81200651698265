.error {
  color: var(--COLOR_ERROR);
}

.input {
  font-size: var(--FONT-SIZE-S);
  color: var(--COLOR_BACKGROUND);
  height: 2rem;
}

.headerStyle {
  background: transparent;
  border: none;
  cursor: pointer;
  width: -webkit-fill-available;
}

.headerStyle:disabled {
  cursor: auto;
}

.notHeaderStyle {
  background: var(--COLOR_ON_SURFACE);
  border: 1px solid var(--COLOR_SECONDARY);
  border-radius: 2px;
}

.notHeaderStyle:focus {
  outline: none;
  border: 1px solid var(--COLOR_SECONDARY);
}
