.select {
  width: 100%;
  height: 2.5rem;
  background: var(--COLOR_ON_SURFACE);
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.select:focus {
  outline: 2px solid var(--COLOR_SECONDARY);
  border: none;
}
