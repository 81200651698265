.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: calc(100vh - 2rem);
  position: relative;
  border-left: 1px solid var(--COLOR_BLACK_80);
  width: calc(100% - 20.6rem);
}

.messagesWrapper {
  overflow-y: hidden;
  flex: 1 1;
  background-color: var(--COLOR_ON_SURFACE);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.messages {
  overflow-y: overlay;
  overflow-x: hidden;
  padding-bottom: 1rem;
}

.hightlight {
  color: var(--COLOR_SECONDARY);
}

.alertWrapper {
  width: 19rem;
  position: fixed;
  top: 6rem;
  right: 10px;
  z-index: 99;
}
