.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.content {
  max-height: 90%;
}

.class0 {
  margin-top: 0;
}

.class1 {
  margin-top: calc(10% + 5.5rem);
}

.class2 {
  margin-top: calc(20% + 11rem);
}

.class3 {
  margin-top: calc(30% + 16.5rem);
}

.class4 {
  margin-top: calc(40% + 21.5rem);
}

.class5 {
  margin-top: calc(50% + 26.5rem);
}

.class6 {
  margin-top: calc(60% + 31.5rem);
}

.class7 {
  margin-top: calc(70% + 36.5rem);
}

.class8 {
  margin-top: calc(80% + 41.5rem);
}

.class9 {
  margin-top: calc(90% + 46.5rem);
}

.class10 {
  margin-top: calc(100% + 51.5rem);
}
