.container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.2rem 1rem 0.2rem;
  margin-top: auto;
  background-color: var(--COLOR_SURFACE);
}

.disabled {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.2rem 1rem 0.2rem;
  margin-top: auto;
  background-color: var(--COLOR_SURFACE);
  cursor: not-allowed;
}

.fieldchat {
  border: 1px solid var(--COLOR_ICON_SECONDARY);
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
  background-color: var(--COLOR_ON_SURFACE);
}

.container :hover {
  border-color: var(--COLOR_SECONDARY);
}

.chattools {
  gap: 0.25rem;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding-top: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  justify-content: flex-start;
  background-color: var(--COLOR_SURFACE);
  min-height: 2.5rem;
}

.tool {
  gap: 0.5rem;
  flex: 1 1 0%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
}

.textsettings {
  gap: 0.75rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatcontent {
  padding-left: 0.5rem;
  padding-right: 2.5rem;
}

.toolsone {
  height: 2.5rem;
  width: 100%;
  display: flex;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0.25rem 0.75rem;
  justify-content: space-between;
}

.tooltwo {
  gap: 0.75rem;
  display: flex;
  align-items: flex-start;
  /* justify-content: flex-start; */
}

.devider {
  height: auto;
  border-color: var(--COLOR_OUTLINE);
  margin: 0;
}

.areacont {
  border: none;
  color: var(--COLOR_OUTLINE);
  font-size: var(--FONT-SIZE-S);
  font-weight: var(--FONT-WEIGHT-S);
  font-family: var(--FONT-FAMILY-MAIN);
  margin-block-end: 0px;
  margin-block-start: 0px;
  outline: none;
  resize: none;
  height: auto;
  width: 100%;
  padding-right: 2.5rem;
  padding: 0;
}

.areacontgroup {
  border: none;
  background-color: var(--COLOR_ON_SURFACE);
  color: var(--COLOR_OUTLINE);
  font-size: var(--FONT-SIZE-S);
  font-weight: var(--FONT-WEIGHT-S);
  font-family: var(--FONT-FAMILY-MAIN);
  margin-block-end: 0px;
  margin-block-start: 0px;
  outline: none;
  resize: none;
  height: auto;
  width: 99%;
}

/* hidden for presentation, remove after */

.chattools {
  min-height: 2.5rem;
}

.upload {
  min-height: 0.25rem;
}
