.headerStyle {
  border: none;
  background: transparent !important;
  min-width: 10.625rem;
}

.ant-picker {
  width: 100%;
}

.ant-picker {
  border-color: var(--COLOR_OUTLINE);
  background-color: var(--COLOR_SURFACE);
}
.ant-picker-dropdown {
  z-index: 5000;
}
.ant-picker-input input {
  color: var(--COLOR_ON_BACKGROUND);
}
.ant-picker-active-bar {
  background-color: var(--COLOR_OUTLINE) !important;
}
.ant-picker-range-separator span span {
  color: var(--COLOR_ON_BACKGROUND);
}
.ant-picker-suffix {
  color: var(--COLOR_ON_BACKGROUND);
}
.ant-picker-focused {
  box-shadow: none;
}
.ant-picker-range-arrow::before {
  background-color: var(--COLOR_OUTLINE);
}
.ant-picker-panels {
  background-color: var(--COLOR_SURFACE);
}
.ant-picker-header {
  color: var(--COLOR_ON_BACKGROUND);
  border-bottom: 1px solid var(--COLOR_OUTLINE);
}
.ant-picker-header-view button:hover {
  color: var(--COLOR_SECONDARY);
}
.ant-picker-clear {
  background-color: var(--COLOR_SURFACE);
  color: var(--COLOR_SECONDARY);
}
.ant-picker-clear:hover {
  background-color: var(--COLOR_SURFACE);
  color: var(--COLOR_SECONDARY);
}
.ant-picker-super-prev-icon,
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  color: var(--COLOR_ON_BACKGROUND);
}
.ant-picker-content thead tr th {
  color: var(--COLOR_ON_BACKGROUND);
}
.ant-picker-cell {
  color: var(--COLOR_OUTLINE);
}
.ant-picker-cell-in-view {
  color: var(--COLOR_ON_BACKGROUND);
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--COLOR_SECONDARY);
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  color: var(--COLOR_ON_SURFACE);
  background-color: var(--COLOR_SECONDARY);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: var(--COLOR_BACKGROUND);
  background-color: var(--COLOR_ON_SECONDARY);
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  border-radius: 0;
}
.ant-picker-cell-in-range .ant-picker-cell-inner {
  background-color: var(--COLOR_SECONDARY);
  color: var(--COLOR_SURFACE);
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--COLOR_SECONDARY);
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: var(--COLOR_SECONDARY);
}
.ant-picker-cell-in-range .ant-picker-cell-inner {
  background-color: transparent;
}
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover::before,
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover::after,
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::before,
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::before,
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-color: var(--COLOR_SECONDARY) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background-color: var(--COLOR_ON_SECONDARY);
  color: var(--COLOR_BACKGROUND);
}
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background-color: var(--COLOR_SECONDARY);
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--COLOR_SECONDARY);
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: var(--COLOR_ON_SECONDARY);
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
  .ant-picker-cell-inner {
  border-radius: 0;
}
.ant-picker-cell-disabled {
  color: var(--COLOR-GRAY-TWO) !important;
}
