.content {
  display: flex;
  color: var(--COLOR_OUTLINE);
  cursor: default;
  font-size: var(--FONT-SIZE-S);
}
.content::before {
  content: '';
  flex: 1;
  border-top: 1px solid var(--COLOR_ON_SURFACE);
  margin: 0.7rem 1.375rem 0 0.5rem;
}
.content:hover::before {
  border-top: 1px solid var(--COLOR_OUTLINE);
}
.content::after {
  content: '';
  flex: 1;
  border-top: 1px solid var(--COLOR_ON_SURFACE);
  margin: 0.7rem 0.5rem 0 1.375rem;
}
.content:hover::after {
  border-top: 1px solid var(--COLOR_OUTLINE);
}
.tools {
  display: flex;
  align-items: center;
}
.tools button {
  margin-left: 0.5rem;
}
