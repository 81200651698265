.wrapper {
  width: 26rem;
  background-color: var(--COLOR_ON_SURFACE);
  border: 1px solid #adb0bc;
}

.content {
  padding: 0 0.5rem;
  overflow-y: auto;
  max-height: calc(100vh * 0.9 - 4rem);
}

.activeClass {
  flex: 1;
  padding-top: 0.5rem;
}

.classWrapper {
  display: flex;
}

.direction {
  display: flex;
  align-items: flex-end;
  padding-left: 0.5rem;
}
.wrapperDirection {
  display: flex;
  border-radius: 3px;
  border: 1px solid var(--COLOR_OUTLINE);
  padding: 1px;
  margin-bottom: 2px;
}

.wrapperDirection .sell {
  background-color: var(--COLOR_ON_SURFACE);
  color: var(--COLOR_OUTLINE);
}
.wrapperDirection .buy {
  background-color: var(--COLOR_ON_SURFACE);
  color: var(--COLOR_OUTLINE);
}

.wrapperDirection .buy.active {
  background-color: #7fe030;
  color: #171920;
}
.wrapperDirection .buy.active:hover {
  background-color: #7fe030;
  color: #171920;
}
.wrapperDirection .sell.active {
  background-color: #e45561;
}
.wrapperDirection .sell.active:hover {
  background-color: #e45561;
}

.wrapperDirection .sell:hover {
  background-color: transparent;
}
.wrapperDirection .buy:hover {
  background-color: transparent;
}

.types {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.wrapper .btn {
  border-radius: 1rem;
  flex-basis: 30%;
  background-color: var(--COLOR_ON_SURFACE);
  color: #edeef2;
  border: 1px solid var(--COLOR_OUTLINE);
}
.wrapper .btn.active {
  background-color: #f2cd40;
  color: #171920;
}
.wrapper .btn:hover {
  background-color: var(--COLOR_ON_SURFACE);
}
.wrapper .btn.active:hover {
  color: #171920;
  background-color: #f2cd40;
}

.section {
  padding: 0.5rem 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  background-color: #2e3240;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}

.footer button {
  width: 45%;
}
