.container {
  gap: 0.5rem;
  width: 100%;
  display: inline-flex;
  box-sizing: border-box;
  align-items: flex-start;
  padding-top: 2px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 2px;
  justify-content: flex-start;
  background-color: var(--COLOR_ON_SURFACE);
}

.left {
  flex: 1 1 0%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.head {
  margin-block-end: 0px;
  margin-block-start: 0px;
  cursor: pointer;
}

.firstword {
  color: var(--COLOR_BACKGROUND);
  display: inline;
  font-size: var(--FONT-SIZE-S);
  margin-block-end: 0px;
  margin-block-start: 0px;
}

.downword {
  color: var(--COLOR_OUTLINE);
  font-size: var(--FONT-SIZE-XS);
  margin-block-end: 0px;
  margin-block-start: 0px;
}

.center {
  flex: 1 1 0%;
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
  align-items: flex-start;
  padding-top: 2px;
  flex-direction: column;
  padding-bottom: 2px;
  justify-content: center;
}

.centertext {
  color: var(--COLOR-GRAY-FIVETEEN);
  font-size: var(--FONT-SIZE-XS);
  margin-block-end: 0px;
  margin-block-start: 0px;
}

.right {
  flex: 1 1 0%;
  display: flex;
  flex-grow: 1;
  gap: 0.5rem;
  box-sizing: border-box;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: flex-start;
}

.righttext {
  color: var(--COLOR_OUTLINE);
  font-size: var(--FONT-SIZE-XS);
  margin-block-end: 0px;
  margin-block-start: 0px;
}

.hightlight {
  color: var(--COLOR_SECONDARY);
}
