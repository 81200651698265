.wrapper,
.status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--COLOR-GRAY-THIRTEEN);
  border-radius: 100%;
  position: relative;
}

.status {
  background-color: transparent;
}
