.tabBar {
  display: flex;
  flex-direction: row;
  height: 2rem;
  padding: 0;
  background: var(--COLOR_BLACK_80);
  align-items: center;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
}

.icon {
  padding: 0.375rem 0.5rem;
}

.title {
  padding: 0.375rem 0.75rem;
  font-weight: var(--FONT-WEIGHT-M);
  font-size: var(--FONT-SIZE-S);
  line-height: 1.25rem;
  color: var(--COLOR_BACKGROUND);
  flex: 1;
}

.wrapper {
  display: flex;
  flex-direction: row;
  padding: 0 0.5rem;
  gap: 0.75rem;
}

@media screen and (max-width: 500px) {
  .title {
    font-size: var(--FONT-SIZE-XS);
  }
}
