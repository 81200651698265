.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--FONT-WEIGHT-M);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.wrapper > svg {
  max-width: 18px;
  max-height: 18px;
  margin: 5px;
}

.xs {
  height: 28px;
}
.s {
  height: 2rem;
}
.m {
  height: 40px;
}

.s .textWrapper {
  font-size: var(--FONT-SIZE-S);
  padding: 0.375rem 0.375rem;
  font-weight: 500;
}
.m .textWrapper {
  font-size: var(--FONT-SIZE-M);
  padding: 10px 5px;
}

.filled:not(.disabled) {
  background-color: var(--COLOR_SECONDARY);
  border: 1px solid var(--COLOR-GRAY-THREE);
  color: var(--COLOR_ON_SURFACE);
}
.filled:not(.disabled) > svg {
  fill: var(--COLOR_ON_SURFACE);
}
.filled:not(.disabled):hover {
  background-color: var(--COLOR-YELLOW-ONE);
}
.filled:not(.disabled):active {
  background-color: var(--COLOR-YELLOW-TWO);
}
.filled.disabled {
  border: 2px solid var(--COLOR_ICON_SECONDARY);
  color: var(--COLOR_ICON_SECONDARY);
  cursor: not-allowed;
}

.outline:not(.disabled) {
  background-color: var(--COLOR_SURFACE);
  border: 1px solid var(--COLOR_SECONDARY);
  color: var(--COLOR_SECONDARY);
}
.outline:not(.disabled) > svg {
  fill: var(--COLOR_SECONDARY);
}
.outline:not(.disabled):hover {
  background-color: #292d3a;
}
.outline:not(.disabled):active {
  background-color: #272b36;
}
.outline.disabled {
  border: 2px solid var(--COLOR-GRAY-NINE);
  color: var(--COLOR_ICON_SECONDARY);
  cursor: not-allowed;
}

.text:not(.disabled) {
  background-color: transparent;
  border: none;
  color: var(--COLOR_SECONDARY);
}
.text:not(.disabled) > svg {
  fill: var(--COLOR_SECONDARY);
}
.text:not(.disabled):hover {
  background-color: var(--COLOR-GRAY-TEN);
}
.text:not(.disabled):active {
  background-color: var(--COLOR-GRAY-ELEVEN);
}
.text.disabled {
  border: none;
  cursor: not-allowed;
  color: var(--COLOR_ICON_SECONDARY);
}

.disabled {
  background-color: transparent;
}
.disabled > svg {
  fill: var(--COLOR_ICON_SECONDARY);
}
