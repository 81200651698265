.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--COLOR_ON_SURFACE);
  font-size: var(--FONT-SIZE-S);
  font-weight: var(--FONT-WEIGHT-S);
  padding: 0.25rem 0.5rem;
}

.top {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: nowrap;
  box-sizing: border-box;
  color: var(--COLOR_ON_BACKGROUND);
}

.bottom {
  flex: 1;
}

.unReadNotification {
  background-color: var(--COLOR-BLUE);
}

.wrapper:hover {
  background-color: var(--COLOR-GRAY-FOURTEEN);
}

.header {
  display: flex;
  color: var(--COLOR_ON_BACKGROUND);
}

.userMargin {
  margin-left: 0.5rem;
}

.user {
  color: var(--COLOR-WHITE-ONE);
}

.company {
  color: var(--COLOR_OUTLINE);
  margin: 0 0.8rem;
}

.messageMargin {
  margin-left: 1.6rem;
}

.infoMessageText {
  color: var(--COLOR-GRAY-FIVETEEN);
  font-family: var(--FONT-FAMILY-MAIN);
  font-size: var(--FONT-SIZE-S);
  font-weight: var(--FONT-WEIGHT-S);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  word-wrap: break-word;
}

.infoMessageIcon {
  display: flex;
  flex-direction: row;
  margin-right: 0.4rem;
}

.message {
  color: var(--COLOR-GRAY-FIVETEEN);
  word-wrap: break-word;
  min-width: 50%;
  font-family: var(--FONT-FAMILY-MAIN);
  font-size: var(--FONT-SIZE-S);
  font-weight: var(--FONT-WEIGHT-S);
  white-space: pre-line;
}

.rightBlock {
  width: 3.75rem;
  display: flex;
  justify-content: flex-end;
  color: var(--COLOR_OUTLINE);
}

.tools {
  display: flex;
  align-items: top;
  justify-content: flex-end;
  right: 0;
}

.date {
  margin-top: 0.4rem;
  font-size: var(--FONT-SIZE-XXS);
  display: flex;
  width: 2.5rem;
  line-height: 1;
  margin-left: auto;
  color: var(--COLOR_OUTLINE);
}
