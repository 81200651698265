.container {
  display: flex;
}

.blocker {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  cursor: default;
  z-index: 999;
}

.header {
  width: 100%;
  padding-top: 0.375rem;
  padding-right: 0.5rem;
  padding-bottom: 0.375rem;
  padding-left: 0.6rem;
  background-color: var(--COLOR_SURFACE);
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 50px;
}

.content {
  max-height: 44px;

  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.headertext {
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  color: var(--COLOR_BACKGROUND);
  font-size: var(--FONT-SIZE-M);
  white-space: nowrap;
  width: 55%;

  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.tools {
  gap: 0.4rem;
  display: flex;
  position: absolute;
  right: 0;
}

.subtitle {
  color: var(--COLOR_OUTLINE);
  font-size: var(--FONT-SIZE-S);
  line-height: 1.25rem;

  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.back {
  position: relative;
  float: left;
  margin-right: 8px;
}

.alertWrapper {
  width: 19rem;
  position: fixed;
  top: 5.5rem;
  right: 0.5rem;
  z-index: 9000;
}
