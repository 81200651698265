.contact {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  background-color: var(--COLOR-GRAY-TWELVE);
  border: none;
  font-weight: var(--FONT-WEIGHT-S);
  white-space: nowrap;
  height: 2rem;
  cursor: pointer;
  padding-left: 0.5rem;
}

.contact:hover {
  background-color: var(--COLOR-GRAY-EIGHTEEN);
}

.selected {
  background-color: var(--COLOR_PRIMARY);
}

.contact input {
  margin: 0 0.7rem 0 0;
}

.contact input:not(:disabled):hover::before {
  box-shadow: none;
}

.contact svg {
  overflow: visible;
}

.title {
  padding: 0 0.5rem;
  color: var(--COLOR_BACKGROUND);
  font-size: var(--FONT-SIZE-M);
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 2;
}

.company {
  color: var(--COLOR_OUTLINE);
  font-size: var(--FONT-SIZE-S);
  padding-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.newMessage {
  background-color: var(--COLOR-YELLOW-THREE);
}

.newMessage:hover {
  background-color: var(--COLOR-YELLOW-FOUR);
}

.isSelected {
  background-color: var(--COLOR_PRIMARY);
}

.isSelected:hover {
  background-color: var(--COLOR-BLUE-TWO);
}

.hover {
  display: flex;
  align-items: center;
  padding-right: 0.75rem;
  gap: 0.5rem;
}
