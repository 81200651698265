.wrapper {
  width: 100%;
}

.input {
  flex: 1;
  padding: 12px;
  background: #171920;
  color: #edeef2;
  border-radius: 2px;
  border: 1px solid #adb0bc;
}

.inputWrapper {
  display: flex;
  width: 100%;
  height: 2.5rem;
  background: #171920;
  position: relative;
}

.label {
  color: #edeef2;
  width: 100%;
}

.input:focus,
.input:hover {
  border: 2px solid #f2cd40;
}

.icon {
  position: absolute;
  right: 0.5rem;
  bottom: 0.6rem;
}
