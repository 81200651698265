.DatePickerModal.ant-picker {
  width: 100%;
  border: 1px solid #adb0bc;
  border-radius: 0;
}

.DatePickerModal.ant-picker:hover,
.ant-picker-focused {
  border: 1px solid var(--COLOR_SECONDARY);
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets {
  display: flex;
  flex-basis: 0;
}
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul li,
.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-presets
  ul
  li
  + li {
  padding: 0;
  margin: 0;
}

.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-presets
  ul
  li:has(div[data-span='3']),
.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-presets
  ul
  li
  + li:has(div[data-span='3']) {
  grid-column: span 3;
}

.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-presets
  ul
  li:has(div[data-span='2']),
.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-presets
  ul
  li
  + li:has(div[data-span='2']) {
  grid-column: span 2;
}
