.wrapper {
  width: 100%;
}
.label {
  color: #edeef2;
}
.wrapper :global .ant-picker-panel-container {
  background-color: blue;
}

.ant-picker:hover,
.ant-picker-focused {
  border: 2px solid #f2cd40 !important;
}
