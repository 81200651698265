.header {
  display: flex;
  width: 100%;
  align-items: center;
  border: none;
  background-color: var(--COLOR-GRAY-TWO);
  max-height: 2rem;
  white-space: nowrap;
  height: 2rem;
  cursor: pointer;
}

.isUnreadHeader {
  background-color: var(--COLOR_ON_SECONDARY);
}

.header input {
  margin: 0 0.7rem 0 0;
}

.header svg {
  overflow: visible;
}

.header input:not(:disabled):hover::before {
  box-shadow: none;
}

.count {
  margin-left: auto;
  color: var(--COLOR_OUTLINE);
  font-size: var(--FONT-SIZE-S);
  font-weight: var(--FONT-WEIGHT-S);
  padding-right: 0.5rem;
}

.divider {
  background-color: var(--COLOR-GRAY-TWO);
  border-color: var(--COLOR-GRAY-TWO);
}

.accordionArrow {
  margin: 0 0.5rem 0 0.25rem;
  transform: rotate(180deg);
}

.isActive {
  transform: rotate(0deg);
}

.title {
  padding: 0 0.5rem;
  color: var(--COLOR_BACKGROUND);
  font-size: var(--FONT-SIZE-M);
  font-weight: var(--FONT-WEIGHT-S);
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  background-color: transparent;
}

.newMessage {
  background-color: var(--COLOR_ON_SECONDARY);
}
