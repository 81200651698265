.container {
  display: inline-flex;
  z-index: 1550;
  height: calc(80vh - (2rem + 218px));
  margin-top: 5px;
}

.searchlist {
  gap: 0.5rem;
  width: 29rem;
  filter: drop-shadow(0px 1.5rem 1.5rem var(--COLOR-GRAY-THREE));
  display: flex;
  overflow: clip;
  box-sizing: border-box;
  align-items: flex-start;
  padding-top: 0.5rem;
  flex-direction: column;
  padding-bottom: 0.5rem;
  justify-content: flex-start;
  background-color: var(--COLOR_ON_SURFACE);
}

.content {
  max-height: calc(100vh - 10rem);
  width: 30rem;
  display: flex;
}

.main {
  gap: 0.5rem;
  width: 30rem;
  display: flex;
  overflow: clip;
  box-sizing: border-box;
  align-items: flex-start;
  padding-right: 0.25rem;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
}

.block {
  width: 30rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.header {
  gap: 0.25rem;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  justify-content: flex-start;
  background-color: var(--COLOR_ON_SURFACE);
}

.text {
  color: var(--COLOR-GRAY-FIVETEEN);
  font-size: var(--FONT-SIZE-XS);
  line-height: 1rem;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

.footer {
  gap: 0.5rem;
  width: 100%;
  display: flex;
  padding: 0.5rem;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: var(--COLOR-GRAY-TEN);
  border-style: solid;
  flex-direction: column;
  justify-content: flex-start;
  border-top-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}

.devider {
  border-color: var(--COLOR_SURFACE);
  margin: 0;
  width: 100%;
}

.footertext {
  color: var(--COLOR_SECONDARY);
  font-size: var(--FONT-SIZE-S);
  line-height: 1.25rem;
  margin: 0px;
}

.noSearch {
  color: var(--COLOR_ON_BACKGROUND);
  margin: 1rem auto;
}
