.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 2px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  border: none;
  outline: none;
}

.wrapper:hover {
  background-color: rgba(235, 239, 244, 0.15);
}

.wrapper:active {
  background-color: rgba(235, 239, 244, 0.3);
}

.disabled {
  cursor: not-allowed;
}

.disabled:hover {
  background-color: transparent;
}

.disabled:active {
  background-color: transparent;
}
