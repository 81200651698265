.root {
  display: flex;
  flex: 1;
  max-width: 20.6rem;
  flex-direction: column;
  position: relative;
  max-height: calc(100vh - 2rem);
  background-color: var(--COLOR-GRAY-TWELVE);
}
.chatList {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  min-width: 20.6rem;
  z-index: 0;
}
.chatList:hover {
  overflow-y: overlay;
}

.alertWrapper {
  width: 19rem;
  position: fixed;
  top: 6rem;
  right: 10px;
  z-index: 99;
}
