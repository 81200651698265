.wrapper {
  max-width: fit-content;
}

.horizontal {
  display: flex;
}

.logoLight {
  margin: 0 auto;
  display: inline-block;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
}
