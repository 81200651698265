.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: calc(100vh - 2rem);
  position: relative;
  border-left: 1px solid var(--COLOR_BLACK_80);
  width: calc(100% - 20.6rem);
}

.messagesWrapper {
  overflow-y: hidden;
  flex: 1 1;
  background-color: #262a34;
  display: flex;
  flex-direction: column;
  margin-top: 0.375rem;
}
