.container {
  gap: 0.5rem;
  width: 100%;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0.5rem 0;
  justify-content: flex-start;
  background-color: var(--COLOR_SURFACE);
}
.wrapper {
  position: relative;
  display: flex;
  flex: 1;
}
