.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: calc(100vh - 2rem);
  position: relative;
  border-left: 1px solid var(--COLOR_BLACK_80);
  width: calc(100% - 20.6rem);
}

.messagesWrapper {
  overflow-y: hidden;
  flex: 1 1;
  background-color: #262a34;
  display: flex;
  flex-direction: column;
  margin-top: 0.375rem;
}

.wrapperContact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
}

.contactHeader {
  width: calc(100% - 1rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  height: 2.5rem;
}

.contactHeader h1 {
  font-weight: var(--FONT-WEIGHT-M);
  font-size: var(--FONT-SIZE-M);
  line-height: 1.5rem;
  color: var(--COLOR_BACKGROUND);
  flex: 1;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
  gap: 0.5rem;
  width: calc(100% - 1rem);
  border-bottom: 1px solid var(--COLOR_SURFACE);
}

.fields span,
p {
  padding: 0;
  margin: 0;
}

.fields span {
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-XS);
  line-height: 1rem;
  color: var(--COLOR_OUTLINE);
}

.fields p {
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-S);
  line-height: 1.25rem;
  color: var(--COLOR_BACKGROUND);
}

.wrapperButtonsNoRole {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  gap: 1rem;
}

.wrapperButtonsWithRole {
  width: calc(100% - 1rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
}

.noRole {
  border: 1px solid var(--COLOR_SECONDARY);
  border-radius: 2px;
  background-color: transparent;
  padding: 0.25rem 0.5rem;
  font-weight: var(--FONT-WEIGHT-M);
  font-size: var(--FONT-SIZE-S);
  line-height: 1.25rem;
  color: var(--COLOR_SECONDARY);
}

.withRole {
  border: none;
  background-color: transparent;
  padding: 0.25rem 0.5rem;
  font-weight: var(--FONT-WEIGHT-M);
  font-size: var(--FONT-SIZE-S);
  line-height: 1.25rem;
  color: var(--COLOR_SECONDARY);
}

.setAdmin {
  border: none;
  background-color: transparent;
  padding: 0.25rem 0.5rem;
  font-weight: var(--FONT-WEIGHT-M);
  font-size: var(--FONT-SIZE-S);
  line-height: 1.25rem;
  color: var(--COLOR_SECONDARY);
}

.iconSelection {
  display: flex;
  flex-direction: row;
}

.iconMargin {
  margin-right: 0.5rem;
}

@media screen and (max-width: 830px) {
  .wrapperContact {
    width: 100%;
    overflow: auto;
  }
}
