.wrapper {
  gap: 0.25rem;
  width: 17.75rem;
  display: inline-flex;
  overflow: clip;
  box-sizing: border-box;
  align-items: flex-start;
  background-color: var(--COLOR_SURFACE);
  font-size: var(--FONT-SIZE-S);
  font-weight: var(--FONT-WEIGHT-S);
  border: 1px solid var(--COLOR-GRAY-TWO);
  flex-direction: column;
  border-radius: 0.125rem;
}
.header {
  gap: 0.25rem;
  display: flex;
  align-self: stretch;
  box-sizing: border-box;
  text-align: left;
  align-items: flex-start;
  padding: 0.25rem 0.5rem;
  flex-direction: column;
}
.title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.currency {
  color: var(--COLOR-GRAY-FIVETEEN);
  font-size: var(--FONT-SIZE-XS);
  line-height: 1rem;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.code {
  color: var(--COLOR_BACKGROUND);
  font-size: var(--FONT-SIZE-XXS);
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.subtitle {
  color: var(--COLOR_OUTLINE);
  display: flex;
  font-size: var(--FONT-SIZE-XXS);
  align-self: stretch;
  line-height: 0.75rem;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.textCompany {
  flex: 1 1 0%;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.textDateTime {
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.content {
  gap: 0.25rem;
  display: flex;
  align-self: stretch;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 0.25rem 0.5rem;
}
.row {
  width: 50%;
  display: flex;
  align-self: stretch;
  text-align: left;
  align-items: flex-start;
  flex-direction: column;
}
.textBottom {
  color: var(--COLOR_OUTLINE);
  font-size: var(--FONT-SIZE-XXS);
  line-height: 0.75rem;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.textBottomSize {
  color: var(--COLOR-WHITE-ONE);
  font-size: var(--FONT-SIZE-S);
  line-height: 1.25rem;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.messageDeal {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: nowrap;
  box-sizing: border-box;
}
.date {
  font-size: var(--FONT-SIZE-XXS);
  display: flex;
  max-width: 2.375rem;
  line-height: 1;
  justify-content: flex-end;
  color: var(--COLOR_OUTLINE);
}
