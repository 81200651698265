.wrapper {
  display: flex;
  justify-content: space-between;
  background-color: var(--COLOR_SURFACE);
  padding: 0.5rem;
}

.leftBlock {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
}

.rightBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.settings {
  position: relative;
  margin: 0 0.75rem;
}

.time {
  color: var(--COLOR_BACKGROUND);
  font-size: var(--FONT-SIZE-M);
}

.deals {
  color: var(--COLOR_OUTLINE);
  font-size: var(--FONT-SIZE-s);
}
.toggle {
  display: flex;
  border: 1px solid #f2cd40;
  border-radius: 3px;
  padding: 2px;
}
.toggle svg {
  fill: #f2cd40;
}
.mainFilter {
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 2px;
}
.isMainDealsFilter {
  background-color: #f2cd40;
}
.isMainDealsFilter svg {
  fill: #171920;
}
