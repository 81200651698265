.subtitle {
  gap: 0.5rem;
  color: var(--COLOR_OUTLINE);
  font-family: var(--FONT-FAMILY-MAIN);
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-S);
  line-height: 20px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}
.number {
  margin-block-end: 0px;
  margin-block-start: 0px;
}
