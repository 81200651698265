:root {
  --COLOR_BLACK_80: #1f222a;

  --COLOR_PRIMARY: #516ec8;
  --COLOR_ON_PRIMARY: #202c50;
  --COLOR_PRIMARY_CONTAINER: #4158a0;
  --COLOR_ON_PRIMARY_CONTAINER: #dce2f4;

  --COLOR_SECONDARY: #f2cd40;
  --COLOR_ON_SECONDARY: #67571a;
  --COLOR_ON_SECONDARY_CONTAINER: #fcf5d9;

  --COLOR_ERROR: #e45561;
  --COLOR_ON_ERROR: #642329;
  --COLOR_ERROR_CONTAINER: #b9444e;
  --COLOR_ON_ERROR_CONTAINER: #fadddf;

  --COLOR_SURFACE: #2e3240;
  --COLOR_ON_SURFACE: #171920;
  --COLOR_BACKGROUND: #edeef2;
  --COLOR_ON_BACKGROUND: #ffffff;

  --COLOR_OUTLINE: #adb0bc;

  --COLOR_ICON_SECONDARY: #6d7385;

  --COLOR-GRAY-ONE: #393c4a;
  --COLOR-GRAY-TWO: #4d546a;
  --COLOR-GRAY-THREE: rgba(0, 0, 0, 0.15);
  --COLOR-GRAY-FOUR: rgba(242, 205, 64, 0.1);
  --COLOR-GRAY-FIVE: rgba(0, 0, 0, 0);
  --COLOR-GRAY-SIX: rgba(0, 0, 0, 0);
  --COLOR-GRAY-SEVEN: rgba(0, 0, 0, 0.1);
  --COLOR-GRAY-NINE: #7a7c7e;
  --COLOR-GRAY-TEN: #292d3a;
  --COLOR-GRAY-ELEVEN: #272b36;
  --COLOR-GRAY-TWELVE: #262a34;
  --COLOR-GRAY-THIRTEEN: #3d414e;
  --COLOR-GRAY-FOURTEEN: #282a31;
  --COLOR-GRAY-FIVETEEN: #b9c5e9;
  --COLOR-GRAY-SIXTEEN: #ebeff414;
  --COLOR-GRAY-SEVENTEEN: #97a8de;
  --COLOR-GRAY-EIGHTEEN: #363a43;
  --COLOR-GRAY-BLOTTER: #8d92a0;

  --COLOR-YELLOW-ONE: #dab93a;
  --COLOR-YELLOW-TWO: #ceae36;
  --COLOR-YELLOW-THREE: #504314;
  --COLOR-YELLOW-FOUR: #5c5126;

  --COLOR-WHITE-ONE: #faebb3;
  --COLOR-BLUE: #516ec8;
  --COLOR-BLUE-TWO: #5d78cc;

  --COLOR-GREEN-ONE: #7fe030;
}
