.groupName {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  gap: 0.5rem;
  height: 2.5rem;
  justify-content: space-between;
  align-items: center;
}

.groupName p {
  font-weight: var(--FONT-WEIGHT-M);
  font-size: var(--FONT-SIZE-M);
  line-height: 1.5rem;
  color: var(--COLOR_BACKGROUND);
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wrapperIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(235, 239, 244, 0.08);
  border-radius: 2.5rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
}

.wrapperIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 0;
  gap: 0.75rem;
}
