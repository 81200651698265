.wrapper {
  background: var(--COLOR_SURFACE);
  padding-bottom: 0.25rem;
}

.chatHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
}

.chatHeader p {
  font-weight: var(--FONT-WEIGHT-M);
  font-size: var(--FONT-SIZE-M);
  line-height: 1.5rem;
  color: var(--COLOR_BACKGROUND);
  margin: 0.75rem 0.5rem;
}

.tools {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
}

.search {
  max-height: 40px;
  margin: 0.25rem 0.5rem;
}
