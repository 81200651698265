.messagesWrapper {
  overflow-y: scroll;
  flex: 1 1;
  background-color: var(--COLOR_ON_SURFACE);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.messages {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 1rem;
}

.hightlight {
  color: var(--COLOR_SECONDARY);
}
.footer {
  display: flex;
  margin-left: auto;
  padding: 0.5rem 1rem 1rem 0;
}
.footer button {
  margin: 0;
  padding: 0;
  max-height: 2rem;
}
