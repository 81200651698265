.wrapper {
  display: flex;
  flex: 1;
  background-color: var(--COLOR_BLACK_80);
  color: var(--COLOR_BACKGROUND);
  font-size: var(--SVG-SIZE-XS);
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  padding: 0 0.3rem;
}

.start {
  display: flex;
  align-items: center;
}

.title {
  margin-left: 0.8rem;
}

.end {
  display: flex;
  align-items: center;
}

.end button {
  margin-left: 6px;
}
