.wrapper {
  display: inline-flex;
  background-color: var(--COLOR_ON_SURFACE);
}

.borderWrapper {
  padding: 0.5rem 0;
  background-color: var(--COLOR_ON_SURFACE);
  border: 1px solid var(--COLOR_SURFACE);
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.24);
  border-radius: 0.125rem;
}

.itemList {
  width: 15rem;
  display: flex;
  flex-direction: column;
  background-color: var(--COLOR_ON_SURFACE);
}

.devider {
  margin: 0.5rem 0;
  border-color: var(--COLOR_OUTLINE);
  background-color: var(--COLOR_ON_SURFACE);
}

.container {
  position: fixed;
  z-index: 1000;
}
