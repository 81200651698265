.wrapper {
  gap: 0.5rem;
  display: inline-flex;
  width: 15rem;
  height: 1.5rem;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--FONT-SIZE-S);
  padding: 0 0.5rem;
  color: var(--COLOR_BACKGROUND);
  background-color: var(--COLOR_ON_SURFACE);
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  white-space: nowrap;
}

.content {
  gap: 0.5rem;
  flex: 1 1 0%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.content svg {
  overflow: visible;
  transition: all 0.2s ease-in-out;
  fill: var(--COLOR_BACKGROUND);
}

.borderWrapper {
  padding: 0.5rem 0;
  background-color: var(--COLOR_ON_SURFACE);
  border: 1px solid var(--COLOR_SURFACE);
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.24);
  border-radius: 0.125rem;
}

.iconContainer {
  width: 14px;
  height: 14px;
}
.wrapperIcon {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
}
.wrapperIcon svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.dropContent {
  position: absolute;
  margin-left: 240px;
  margin-top: -24px;
  padding: 0.5rem 0;
  background-color: var(--COLOR_ON_SURFACE);
  border: 1px solid var(--COLOR_SURFACE);
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.24);
  border-radius: 0.125rem;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--FONT-SIZE-XS);
}

.wrapper:not(.disabled):hover {
  background-color: var(--COLOR_ON_SECONDARY);
}
.disabled {
  background-color: var(--COLOR_ON_SURFACE);
  color: var(--COLOR_ICON_SECONDARY);
}

.disabled svg {
  transition: all 0.2s ease-in-out;
  fill: var(--COLOR_ICON_SECONDARY);
}
