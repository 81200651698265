.wrapper {
  display: flex;
  height: 80vh;
  color: var(--COLOR_BACKGROUND);
}

.titleWrapper {
  display: inline-flex;
  margin-left: 0.5rem;
  vertical-align: top;
  margin-top: 0.05rem;
}

.title {
  height: 3rem;
  padding: 0.5rem;
  background-color: var(--COLOR_SURFACE);
}
.title_right {
  height: 3rem;
  padding: 0.5rem;
  background-color: var(--COLOR_SURFACE);
  text-align: right;
}

.leftBlock {
  display: flex;
  flex-direction: column;
  width: 18.75rem;
  background-color: var(--COLOR_BLACK_80);
}
.rightBlock {
  width: 18.75rem;
  background-color: var(--COLOR-GRAY-TWELVE);
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.chat {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem 0.2rem 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid var(--COLOR_BLACK_80);
}

.chat:hover {
  background-color: var(--COLOR-BLUE);
}

.header {
  display: flex;
  flex-direction: column;
}
.chatName {
  padding: 0 0.5rem;
  max-width: 60%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.date {
  font-size: var(--FONT-SIZE-XXS);
  color: var(--COLOR_OUTLINE);
  display: flex;
  max-width: 2.375rem;
  line-height: 1;
  margin-left: auto;
}

.message {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--FONT-SIZE-S);
  padding-top: 0.3rem;
}

.more {
  margin-left: auto;
  color: var(--COLOR_SECONDARY);
}

.input {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.messageWrapper {
  background-color: var(--COLOR_ON_SURFACE);
}
.messagesWrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100% - 7rem);
  min-height: calc(100% - 7rem);
  background-color: var(--COLOR_ON_SURFACE);
  justify-content: flex-end;
}

.messages {
  overflow: auto;
}

.chatFooter {
  background-color: var(--COLOR-GRAY-TWELVE);
  text-align: right;
}

.company {
  color: var(--COLOR_OUTLINE);
}
.wrapperFounds {
  overflow-x: hidden;
  height: 100%;
  max-height: calc(80vh - 3rem);
}
.contactName {
  display: flex;
  font-size: var(--FONT-SIZE-XS);
  color: var(--COLOR_OUTLINE);
  align-items: flex-end;
}

.chatTitle {
  display: flex;
  flex-direction: row;
}

.contactName span {
  margin-left: 1rem;
}

.label {
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-XS);
}

.messageOwner {
  font-size: var(--FONT-SIZE-S);
  color: var(--COLOR-GRAY-SEVENTEEN);
  white-space: nowrap;
}

.foundMessage {
  padding-left: 0.5rem;
}

.defaultDate {
  cursor: pointer;
  background-color: var(--COLOR_ON_SURFACE);
  border: none;
  display: flex;
  flex: 1;
  height: 2.5rem;
  align-items: center;
  padding-right: 0.5rem;
}
.defaultDate button {
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;
}
