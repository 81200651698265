.container,
.selected {
  display: flex;
  align-items: center;
  background-color: var(--COLOR_BLACK_80);
}

.tab {
  display: flex;
  max-width: 10rem;
  justify-content: space-between;
  padding: 0.375rem 0.5rem;
  color: var(--COLOR_OUTLINE);
  white-space: nowrap;
  overflow: hidden;
}

.popper {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  color: var(--COLOR_OUTLINE);
  white-space: nowrap;
}

.tab .rightIcon {
  opacity: 0;
}

.container:hover {
  background: var(--COLOR_SURFACE);
  border-radius: 2px 2px 0px 0px;
}

.container .tab:hover {
  color: var(--COLOR_BACKGROUND);
}

.selected {
  background: var(--COLOR_SURFACE);
  border-radius: 2px 2px 0px 0px;
}

.container:hover .rightIcon {
  opacity: 1;
}

.selected .rightIcon {
  color: var(--COLOR-GRAY-TWO);
  opacity: 1;
}

.selected:hover .rightIcon {
  color: var(--COLOR_BACKGROUND);
}

.label {
  font-size: var(--FONT-SIZE-S);
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leftIcon,
.rightIcon {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.rightIcon {
  max-height: 1.25rem;
}

.underline {
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.375rem;
  background: var(--COLOR_SURFACE);
  color: var(--COLOR_OUTLINE);
  box-sizing: border-box;
}

.underline .rightIcon {
  display: none;
}

.underline:hover,
.underline:focus {
  color: var(--COLOR_SECONDARY);
  border-bottom: 1px SOLID var(--COLOR_SECONDARY);
}
.newMessage {
  background-color: var(--COLOR_ON_SECONDARY);
}
/* TODO edit underline focus to selected */
