.wrapper {
  display: flex;
  background-color: var(--COLOR_ON_SURFACE);
  font-size: var(--FONT-SIZE-S);
  font-weight: var(--FONT-WEIGHT-S);
  padding: 0.25rem 0.5rem;
  justify-content: space-between;
  gap: 0.5rem;
}

.leftBlock {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

.wrapper:hover {
  background-color: var(--COLOR-GRAY-FOURTEEN);
}

.rightBlock {
  color: var(--COLOR_OUTLINE);
  position: relative;
}

.tools {
  display: flex;
  width: 2.375rem;
  line-height: 1;
  margin: auto;
  justify-content: center;
}

.date {
  font-size: var(--FONT-SIZE-XXS);
  display: flex;
  max-width: 2.375rem;
  line-height: 1;
}

.user {
  padding-top: 0.5rem;
  padding-left: 0.25rem;
}

.header {
  display: flex;
  color: var(--COLOR_ON_BACKGROUND);
}

.user {
  color: var(--COLOR-WHITE-ONE);
}

.company {
  color: var(--COLOR_OUTLINE);
  margin-left: 0.8rem;
  margin-top: 0.5rem;
}
