.wrapper {
  display: flex;
  align-items: center;

  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-M);
  text-align: center;
}

.m {
  height: 2rem;
}

.s {
  height: 1.5rem;
}
