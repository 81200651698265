.wrapper {
  background-color: var(--COLOR_BLACK_80);
  color: var(--COLOR_BACKGROUND);
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.title {
  font-size: var(--FONT-SIZE-S);
}
