.fileInfoBox {
  display: inline-flex;
  background-color: var(--COLOR_SURFACE);
  padding: 0.3rem;
  border: 0.05rem var(--COLOR-GRAY-TWO) solid;
  border-radius: 0.2rem;
  cursor: pointer;
}

.container {
  display: inline-block;
}

.fileName {
  margin-left: 0.3rem;
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-S);
  color: var(--COLOR_SECONDARY);
  overflow-wrap: anywhere;
}

.fileSize {
  margin-left: 1.5rem;
  font-weight: var(--FONT-WEIGHT-S);
  font-size: var(--FONT-SIZE-S);
  color: var(--COLOR_OUTLINE);
}

.wrapperIcon {
  display: flex;
}

.prepared {
  padding-left: 1rem;
}
